import React, { useState } from 'react';
import './App.css';
import { Card, CardContent, CardHeader } from './components/ui/card';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';
import { Settings, Upload, ArrowLeft } from 'lucide-react';
import ScoreChart from './components/ScoreChart';
import DataUploadScreen from './components/DataUploadScreen'; // 新しいファイルからインポート
import LoginScreen from './components/LoginScreen'; // 新しいファイルからインポート

type Screen = 'login' | 'cameraControl' | 'cameraSettings' | 'dataUpload';

interface BackButtonProps {
  onBack: () => void;
}

const CameraSettingsScreen: React.FC<BackButtonProps> = ({ onBack }) => (
  <Card className="w-[400px] mx-auto mt-10">
    <CardHeader title="カメラ設定" />
    <CardContent>
      <div className="space-y-4">
        <div>
          <label>露出</label>
          <Input type="number" />
        </div>
        <div>
          <label>明るさ</label>
          <Input type="number" />
        </div>
        <div>
          <label>フォーカス</label>
          <Input type="number" />
        </div>
        <Button className="w-full">設定を保存</Button>
        <Button className="w-full" variant="outline" onClick={onBack}>
          <ArrowLeft className="mr-2" />戻る
        </Button>
      </div>
    </CardContent>
  </Card>
);

const CameraControlScreen: React.FC<{ onNavigate: (screen: Screen) => void }> = ({ onNavigate }) => {
  const generateScores = () => {
    const scores = Array.from({ length: 100 }, () => 3.5 + Math.random() * 1.5); // 3.5から5.0の範囲でランダムな値を生成
    // 2〜3個のポイントを3.5以下に設定
    scores[Math.floor(Math.random() * 100)] = 3.4;
    scores[Math.floor(Math.random() * 100)] = 3.3;
    if (Math.random() > 0.5) {
      scores[Math.floor(Math.random() * 100)] = 3.2;
    }
    return scores;
  };

  const [scores] = useState<number[]>(generateScores());
  const sharpness1 = useState<number>(3.8)[0]; // カメラ1のSharpnessデータ
  const sharpness2 = useState<number>(4.0)[0]; // カメラ2のSharpnessデータ
  const camera1Status = useState<'登録中' | '待機中'>('登録中')[0];
  const camera2Status = useState<'認証中' | '待機中'>('認証中')[0];
  const [column, setColumn] = useState<string>('A'); // 列を指定するためのステート
  const [numColumns, setNumColumns] = useState<number>(4); // 列数を指定するためのステート
  const currentColumn = useState<number>(0)[0]; // 現在の撮像列
  const alarmValue = 3.5; // アラーム設定値
  const lowerLimit = 2.0; // 下限値

  const handleStartCapture = () => {
    const folderName = `Reg-${String.fromCharCode(65 + currentColumn)}`;
    alert(`撮像開始: ${folderName}`);
    // ここに撮像開始のロジックを追加
  };

  const handleStopCapture = () => {
    alert('撮像停止');
    // ここに撮像停止のロジックを追加
  };

  const handleColumnChange = (direction: 'up' | 'down') => {
    setColumn((prevColumn) => {
      const newCharCode = direction === 'up' ? prevColumn.charCodeAt(0) + 1 : prevColumn.charCodeAt(0) - 1;
      if (newCharCode < 65) return 'A'; // 'A'より下には行かない
      if (newCharCode > 65 + numColumns - 1) {
        alert('すべての列の撮像が完了しました');
        return String.fromCharCode(65 + numColumns - 1); // 列数を超えない
      }
      return String.fromCharCode(newCharCode);
    });
  };

  return (
    <div className="flex space-x-4">
      <div className="flex-1">
        <Card className="w-full mx-auto mt-10">
          <CardHeader title="カメラ操作" />
          <CardContent>
            <div className="flex space-x-4">
              <div className="flex-1 text-center relative">
                <div className="absolute top-0 left-0 bg-white px-2 py-1">登録</div>
                <div className="border-2 border-dashed border-gray-300 p-32 relative">
                  カメラプレビュー
                  <div className={`absolute bottom-0 right-0 px-2 py-1 text-white ${camera1Status === '登録中' ? 'bg-red-500' : 'bg-green-500'}`}>
                    {camera1Status}
                  </div>
                </div>
                <Button className="w-full mt-2" onClick={() => onNavigate('cameraSettings')}>
                  <Settings className="mr-2" />カメラ設定
                </Button>
                <div className="text-sm mt-2">
                  <h4 className="font-bold">Sharpnessデータ: {sharpness1}</h4>
                </div>
              </div>
              <div className="flex-1 text-center relative">
                <div className="absolute top-0 left-0 bg-white px-2 py-1">認証</div>
                <div className="border-2 border-dashed border-gray-300 p-32 relative">
                  カメラプレビュー
                  <div className={`absolute bottom-0 right-0 px-2 py-1 text-white ${camera2Status === '認証中' ? 'bg-blue-500' : 'bg-green-500'}`}>
                    {camera2Status}
                  </div>
                </div>
                <Button className="w-full mt-2" onClick={() => onNavigate('cameraSettings')}>
                  <Settings className="mr-2" />カメラ設定
                </Button>
                <div className="text-sm mt-2">
                  <h4 className="font-bold">Sharpnessデータ: {sharpness2}</h4>
                </div>
              </div>
            </div>
            <div className="mt-4 flex space-x-4">
              <div className="flex-1">
                <label className="block font-bold mb-2">列数を指定:</label>
                <Input className="w-full" type="number" value={numColumns} onChange={(e) => setNumColumns(Number(e.target.value))} placeholder="列数を入力" />
              </div>
              <div className="flex-1">
                <label className="block font-bold mb-2">列を指定:</label>
                <div className="flex items-center">
                  <Button onClick={() => handleColumnChange('down')}>-</Button>
                  <Input className="w-full mx-2 text-center" value={column} readOnly />
                  <Button onClick={() => handleColumnChange('up')}>+</Button>
                </div>
              </div>
            </div>
            <div className="mt-4 flex space-x-2">
              <Button className="w-full" onClick={handleStartCapture}>撮像開始</Button>
              <Button className="w-full" onClick={handleStopCapture}>撮像停止</Button>
            </div>
            <div className="mt-4">
              <svg width="100%" height={numColumns * 100} viewBox={`0 0 100 ${numColumns * 100}`} xmlns="http://www.w3.org/2000/svg">
                {Array.from({ length: numColumns }).map((_, index) => (
                  <g key={index}>
                    <rect x="0" y={index * 100} width="100" height="100" fill={String.fromCharCode(65 + index) === column ? 'rgba(0, 0, 255, 0.3)' : 'none'} stroke="black" />
                    <text x="50" y={index * 100 + 50} textAnchor="middle" alignmentBaseline="middle" fontSize="24">{String.fromCharCode(65 + index)}</text>
                  </g>
                ))}
              </svg>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="flex-1">
        <Card className="w-full mx-auto mt-10">
          <CardHeader title="スコア" />
          <CardContent>
            <div className="p-4 bg-gray-100 rounded">
              <h3 className="font-bold text-lg">スコア</h3>
              <ScoreChart data={scores} alarmValue={alarmValue} lowerLimit={lowerLimit} />
            </div>
            <div className="mt-4">
              <h3 className="font-bold">登録情報</h3>
              <table className="w-full text-left">
                <tbody>
                  <tr>
                    <th className="border px-4 py-2">日付</th>
                    <td className="border px-4 py-2">〇〇〇〇年〇〇月〇〇日</td>
                  </tr>
                  <tr>
                    <th className="border px-4 py-2">ジョブ番号</th>
                    <td className="border px-4 py-2">〇〇〇〇〇〇</td>
                  </tr>
                  <tr>
                    <th className="border px-4 py-2">撮像列</th>
                    <td className="border px-4 py-2">{String.fromCharCode(65 + currentColumn)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Button className="w-full mt-4" onClick={() => onNavigate('dataUpload')}>
              <Upload className="mr-2" />データアップロード
            </Button>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const App: React.FC = () => {
  const [currentScreen, setCurrentScreen] = useState<Screen>('login');

  const navigateTo = (screen: Screen) => setCurrentScreen(screen);
  const goBack = () => setCurrentScreen('cameraControl');

  return (
    <div className="container mx-auto p-4">
      {currentScreen === 'login' && <LoginScreen onLogin={() => navigateTo('cameraControl')} />}
      {currentScreen === 'cameraControl' && <CameraControlScreen onNavigate={navigateTo} />}
      {currentScreen === 'cameraSettings' && <CameraSettingsScreen onBack={goBack} />}
      {currentScreen === 'dataUpload' && <DataUploadScreen onBack={goBack} />}
    </div>
  );
};

export default App;
