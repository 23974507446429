import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

interface ScoreChartProps {
  data: number[];
  alarmValue: number;
  lowerLimit: number;
}

const ScoreChart: React.FC<ScoreChartProps> = ({ data, alarmValue, lowerLimit }) => {
  const chartData = {
    labels: data.map(() => ''), // ラベルを空にする
    datasets: [
      {
        label: 'スコア',
        data: data,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(0, 0, 0, 0)', // 背景色を透明にする
        pointBackgroundColor: data.map(value => value < alarmValue ? 'rgba(255, 99, 132, 1)' : 'rgba(75, 192, 192, 1)'), // 点の色を条件に応じて変更
        fill: false,
        tension: 0.4, // グラフを滑らかにする
      },
      {
        label: 'アラーム設定値',
        data: Array(data.length).fill(alarmValue),
        borderColor: 'rgba(255, 0, 0, 1)',
        borderDash: [10, 5],
        pointRadius: 0,
        fill: false,
      },
      {
        label: '下限値',
        data: Array(data.length).fill(lowerLimit),
        borderColor: 'rgba(0, 0, 255, 1)',
        borderDash: [10, 5],
        pointRadius: 0,
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: 5,
      },
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'スコアの推移',
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default ScoreChart;
