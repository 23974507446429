import React, { useState } from 'react';
import { Card, CardContent, CardHeader } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { LogIn } from 'lucide-react';

interface LoginScreenProps {
  onLogin: () => void;
}

const LoginScreen: React.FC<LoginScreenProps> = ({ onLogin }) => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    const requiredString = '@';
    if (userId.includes(requiredString) && password === 'qwerty1234') {
      onLogin();
    } else {
      alert('ユーザーIDまたはパスワードが間違っています、または特定の文字列が含まれていません');
    }
  };

  return (
    <Card className="w-[300px] mx-auto mt-10">
      <CardHeader title="ログイン" />
      <CardContent>
        <Input className="mb-4" placeholder="ユーザーID" value={userId} onChange={(e) => setUserId(e.target.value)} />
        <Input className="mb-4" type="password" placeholder="パスワード" value={password} onChange={(e) => setPassword(e.target.value)} />
        <Button className="w-full" onClick={handleLogin}><LogIn className="mr-2" />ログイン</Button>
      </CardContent>
    </Card>
  );
};

export default LoginScreen;
