import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import { Card, CardContent, CardHeader } from './ui/card';
import { Button } from './ui/button';
import { Check, ArrowLeft, Upload } from 'lucide-react'; // Uploadを追加

interface BackButtonProps {
  onBack: () => void;
}

interface SelectedData {
  status: '未実施' | '成功' | '失敗';
  date: string;
  folderPath?: string;
}

const DataUploadScreen: React.FC<BackButtonProps> = ({ onBack }) => {
  const [step, setStep] = useState<'select' | 'confirm' | 'success' | 'error'>('select');
  const [selectedData, setSelectedData] = useState<SelectedData>({
    status: '未実施',
    date: new Date().toLocaleString()
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.setAttribute('webkitdirectory', 'true');
      fileInputRef.current.setAttribute('directory', 'true');
    }
  }, []);

  const handleUpload = () => setStep('confirm');
  const confirmUpload = () => {
    const isSuccess = Math.random() > 0.5;
    setSelectedData(prev => ({
      ...prev,
      status: isSuccess ? '成功' : '失敗',
      date: new Date().toLocaleString()
    }));
    setStep(isSuccess ? 'success' : 'error');
  };
  const resetUpload = () => {
    setStep('select');
    setSelectedData({ status: '未実施', date: new Date().toLocaleString() });
  };

  return (
    <Card className="w-[800px] mx-auto mt-10">
      <CardHeader title="データアップロード" />
      <CardContent>
        {step === 'select' && (
          <div className="flex space-x-4">
            <div className="flex-1 space-y-4">
              <div className="flex items-center space-x-2">
                <input
                  type="file"
                  className="mb-4"
                  ref={fileInputRef}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const folderPath = e.target.files?.[0]?.webkitRelativePath.split('/')[0];
                    setSelectedData(prev => ({ ...prev, folderPath }));
                  }}
                />
              </div>
              <Button
                className="w-full"
                onClick={handleUpload}
              >
                <Upload className="mr-2" />アップロード
              </Button>
              <div className="mt-4">
                <h3 className="font-bold">選択フォルダ情報</h3>
                <table className="w-full text-left">
                  <tbody>
                    <tr>
                      <th className="border px-4 py-2">日付</th>
                      <td className="border px-4 py-2">〇〇〇〇年〇〇月〇〇日</td>
                    </tr>
                    <tr>
                      <th className="border px-4 py-2">ジョブ番号</th>
                      <td className="border px-4 py-2">〇〇〇〇〇〇</td>
                    </tr>
                    <tr>
                      <th className="border px-4 py-2">撮像列</th>
                      <td className="border px-4 py-2">D</td>
                    </tr>
                    <tr>
                      <th className="border px-4 py-2">登録・認証</th>
                      <td className="border px-4 py-2">登録</td>
                    </tr>
                    <tr>
                      <th className="border px-4 py-2">ファイル数</th>
                      <td className="border px-4 py-2">〇〇〇枚</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex-1">
              <h3 className="font-bold">画像プレビュー</h3>
              <div className="image-preview">
                画像プレビュー
              </div>
            </div>
          </div>
        )}
        {step === 'confirm' && (
          <div className="space-y-4">
            <p>以下のデータをアップロードします：</p>
            <ul className="list-disc list-inside">
              <li>フォルダ: {selectedData.folderPath}</li>
            </ul>
            <Button className="w-full" onClick={confirmUpload}>確認</Button>
            <Button className="w-full" variant="outline" onClick={resetUpload}>キャンセル</Button>
          </div>
        )}
        {step === 'success' && (
          <div className="space-y-4 text-center">
            <Check className="text-green-500 w-16 h-16 mx-auto" />
            <p>データのアップロードが完了しました。</p>
            <Button onClick={resetUpload}>新しいアップロード</Button>
          </div>
        )}
        {step === 'error' && (
          <div className="space-y-4 text-center">
            <div className="text-red-500 text-4xl">×</div>
            <p>データのアップロードに失敗しました。</p>
            <Button onClick={resetUpload}>再試行</Button>
          </div>
        )}
        <Button className="w-full mt-4" variant="outline" onClick={onBack}>
          <ArrowLeft className="mr-2" />戻る
        </Button>
      </CardContent>
    </Card>
  );
};

export default DataUploadScreen;
